<template>
  <main></main>
</template>

<script>

export default {
  name: 'Meeting',
  created() {
    this.ZoomMtg.setZoomJSLib('https://source.zoom.us/2.9.7/lib', '/av');
    this.ZoomMtg.preLoadWasm();
    this.ZoomMtg.prepareWebSDK();
    this.ZoomMtg.i18n.load('en-US');
    this.ZoomMtg.i18n.reload('en-US');
    this.startMeeting();
    window.addEventListener('beforeunload', function () {
      this.ZoomMtg.leaveMeeting();
    }, false)
  },
  mounted() {
    window.setInterval(() => {
      var element = document.querySelector('[aria-label="Gallery View"]')
      if (element && this.switchToGalleryView) {
        this.eventFire(element, 'click');
        this.switchToGalleryView = false;
      }
    }, 1000)
  },
  data() {
    return {
      sdkKey: this.$route.query.sdkKey,
      leaveUrl: this.$route.query.leaveUrl ? this.$route.query.leaveUrl : "https://safespace.familyroutes.co.uk/",
      meetingNumber: this.$route.query.meetingNumber,
      password: this.$route.query.password,
      role: this.$route.query.role,
      email: this.$route.query.email,
      username: this.$route.query.name,
      signature: this.$route.query.sdkSignature,
      switchToGalleryView: true,
    }
  },
  methods: {
    eventFire(el, etype) {
      if (el.fireEvent) {
        el.fireEvent('on' + etype);
      } else {
        var evObj = document.createEvent('Events');
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
      }
    },
    startMeeting() {
      document.getElementById("zmmtg-root").style.display = "block";

      if (this.meetingNumber && this.signature) {
        this.ZoomMtg.init({
          leaveUrl: this.leaveUrl,
          isSupportAV: true,
          isSupportPolling: false,
          success: (success) => {
            console.log(success);
            this.ZoomMtg.join({
              meetingNumber: this.meetingNumber,
              userName: this.username,
              signature: this.signature,
              sdkKey: this.sdkKey,
              userEmail: this.email,
              passWord: this.password,
              success: (success) => {
                console.log(success);
              },
              error: (error) => {
                console.log(error);
              }
            });
          },
          error: (error) => {
            console.log(error);
          }
        });
      } else {
        return window.location = this.leaveUrl;
      }
    }
  }
}
</script>

<style scoped>
main {
  width: 70%;
  margin: auto;
  text-align: center;
}

main button {
  margin-top: 20px;
  background-color: #3F3D56;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #3F3D56;
}
</style>
