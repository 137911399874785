import { createRouter, createWebHistory } from 'vue-router'
import Meeting from "./components/Meeting";

const router = createRouter({
    history: createWebHistory("/"),
    routes: [
        {path: '/', component: Meeting},
    ],
})

export default router;