<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  width: 70%;
  margin: auto;
  text-align: center;
}

#zmmtg-root {
  display: none;
}

#content_container {
  background-color: #AEDBD6 !important;
}

.joinWindowBtn {
  background-color: #3ABD98 !important;
  color: white;
  border-color: #3ABD98 !important;
}

.meeting-title {
  display: none;
}
</style>
